import { useMemo } from 'react';

import { getZoneId } from '~/shared/services/analytics';
import { PageType } from '~/shared/types/shared';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export default function useWalkerGlobal(page: PageType, request: RequestType) {
  const walkerGlobal = useMemo(() => {
    const zoneId = getZoneId(
      page?.route?.zoneId,
      request?.contentType,
    ) as string;
    let globals = '';

    if (zoneId) {
      globals += `content_zone:${zoneId};`;
    }

    if (request?.contentType) {
      globals += `content_type:${request?.contentType}`;
    }

    return globals;
  }, [request?.contentType, page?.route?.zoneId]);

  return walkerGlobal;
}
