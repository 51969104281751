import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';

import { richTextPropType } from '~/shared/util/shared-prop-types';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import dataSelector from '~/shared/util/data-selector';

const StyledCol = styled(Col)(css`
  text-align: center;
`);

/**
 * Communicate terms and conditions in small print
 */
function FinePrint({ content = {}, ...rest }) {
  if (isEmpty(content)) {
    return null;
  }

  return (
    <Grid
      {...rest}
      data-selector={dataSelector('section', 'fine_print')}
      data-elbcontext="component:fine_print"
    >
      <Row>
        <StyledCol span="12">
          <RichText
            richText={content}
            renderNode={inline.createRenderNode(inline.TWO)}
            renderMark={inline.createRenderMark(inline.TWO)}
          />
        </StyledCol>
      </Row>
    </Grid>
  );
}

FinePrint.propTypes = {
  /**
   * The text. Supports simple markdown.
   */
  content: richTextPropType,
  /**
   * Enables custom styles.
   */
  className: PropTypes.string,
};

/**
 * @component
 */
export default FinePrint;
