import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import { MARKS } from '@contentful/rich-text-types';

import FinePrint from '~/shared/components/FinePrint';
import { createRichTextText, getContent } from '~/shared/util/rich-text';
import dataSelector from '~/shared/util/data-selector';
import {
  richTextPropType,
  footnoteConfigPropType,
} from '~/shared/util/shared-prop-types';

const finePrintStyles = ({ theme, asNumber }) => css`
  text-align: center;
  margin-bottom: ${theme.spacings.kilo};

  p > em:first-child {
    display: inline-flex;
    vertical-align: ${asNumber && 'super'};
    font-size: ${asNumber && 'x-small !important'};
    font-style: normal !important;
  }
`;

const StyledFinePrint = styled(FinePrint)(finePrintStyles);

function addMissedFootnote(terms, footnoteConfig) {
  const content = getContent(terms);

  if (
    content[0].value &&
    !(/\*/.test(content[0].value) || /^\d/.test(content[0].value))
  ) {
    const contentWithFoonote = [
      // using mark to render <em /> tag because usage of RichText paragraphs generates non-semantic HTML:
      // e.g.  "validateDOMNesting(...): <p> cannot appear as a descendant of <p>."
      createRichTextText(footnoteConfig.footnote, [{ type: MARKS.ITALIC }]),
      ...content,
    ];

    return {
      ...terms,
      content: [{ ...terms.content[0], content: contentWithFoonote }],
    };
  }
  return terms;
}

function TermsAndConditionsLine({
  id = 'default',
  content = {},
  footnoteConfig = { footnoteAsNumber: false, footnote: '*' },
}) {
  if (isEmpty(content)) {
    return null;
  }

  const contentWithFootNote = addMissedFootnote(content, footnoteConfig);

  return (
    <StyledFinePrint
      asNumber={footnoteConfig.footnoteAsNumber}
      content={contentWithFootNote}
      data-selector={dataSelector('fine_print', `terms-conditions-${id}`)}
    />
  );
}

TermsAndConditionsLine.propTypes = {
  id: PropTypes.string,
  content: richTextPropType,
  footnoteConfig: footnoteConfigPropType,
};

export default TermsAndConditionsLine;
