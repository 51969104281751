import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import TermsAndConditionsLine from './components/TermsAndConditionsLine';

import { footnoteConfigPropType } from '~/shared/util/shared-prop-types';

const baseStyles = ({ theme }) => css`
  margin: ${theme.spacings.tera} 0;
`;

const Wrapper = styled('div')(baseStyles);

function TermsAndConditions({
  products = {},
  termsOverride = {},
  footnoteConfig = { footnoteAsNumber: false, footnote: '*' },
}) {
  if (!isEmpty(termsOverride)) {
    return (
      <Wrapper data-elbcontext="component:terms_and_conditions">
        <TermsAndConditionsLine
          content={termsOverride.text}
          footnoteConfig={footnoteConfig}
        />
      </Wrapper>
    );
  }

  const productsWithTerms = values(products).filter(
    ({ showTerms }) => showTerms,
  );

  if (isEmpty(productsWithTerms)) {
    return null;
  }

  return (
    <Wrapper data-elbcontext="component:terms_and_conditions">
      {productsWithTerms.map(
        ({ productId, terms, footnoteConfig: productFootnote }) => (
          <TermsAndConditionsLine
            key={productId}
            id={productId}
            content={terms}
            footnoteConfig={productFootnote}
          />
        ),
      )}
    </Wrapper>
  );
}

TermsAndConditions.propTypes = {
  products: PropTypes.object,
  termsOverride: PropTypes.object,
  footnoteConfig: footnoteConfigPropType,
};

/**
 * @component
 */
export default TermsAndConditions;
