import React from 'react';
import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

import BrandArea from '~/shared/components/Navigation/components/BrandArea';
import { InfoBanner as InfoBannerType } from '~/shared/components/HeaderNavigation/interfaces';
import InfoBanner from '~/shared/components/HeaderNavigation/components/InfoBanner';
import useMeasurePerformance from '~/shared/hooks/use-measure-performance';
import { useScrolledPastThreshold } from '~/shared/hooks/use-scroll';
import { PartnerType } from '~/shared/types/shared';

const SCROLL_THRESHOLD = 350;

const headerBaseStyles = ({
  theme,
  isSticky,
}: {
  theme?: Theme;
  isSticky?: boolean;
}) => css`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${theme.zIndex.navigation};
  background-color: var(--cui-bg-normal);

  ${theme.mq.untilMega} {
    box-shadow: 0 1px 2px 0 rgba(12, 15, 20, 0.07);
  }

  ${isSticky &&
  `
    border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
    position: sticky;
    animation-name: navslidein;
    animation-duration: 0.3s;
`}

  @keyframes navslidein {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const Header = styled('header')(headerBaseStyles);

const wrapperStyles = ({ theme }: { theme?: Theme }) => css`
  display: flex;
  align-items: center;
  position: relative;
  z-index: ${theme.zIndex.navigation};
  height: ${theme.spacings.nav.mobile};
  padding: 0 ${theme.spacings.giga};
  gap: min(${theme.spacings.giga}, 1vw);
  justify-content: space-between;

  @media (min-width: calc(${theme.maxWidth} + 1px)) {
    padding: 0;
    margin: 0 auto;
    height: ${theme.spacings.nav.desktop};
    width: ${theme.maxWidth};
    gap: ${theme.spacings.giga};
  }
`;

const Wrapper = styled('div')(wrapperStyles);

type Props = {
  logoLink: boolean;
  partner: PartnerType;
  contentType: string;
  name: string;
  id: string;
  infoBanner: InfoBannerType;
  hideInfoBanner?: boolean;
};

/**
 * The Navigation contains the branding, info banner and partner logo
 */
function PartnerNavigation(props: Props) {
  const {
    logoLink,
    partner = {},
    contentType,
    name,
    id,
    infoBanner,
    hideInfoBanner = true,
  } = props;

  useMeasurePerformance('navigation-component-render');

  const isSticky = useScrolledPastThreshold(SCROLL_THRESHOLD);
  const hasInfoBanner = !!infoBanner?.name && !hideInfoBanner;

  const trackingContentEntry = React.useMemo(
    () => ({
      contentType,
      contentEntryName: name,
      contentEntryId: id,
    }),
    [contentType, id, name],
  );

  return (
    <>
      {hasInfoBanner && <InfoBanner {...infoBanner} />}
      <Header isSticky={isSticky}>
        <Wrapper>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <BrandArea
            primaryLogoLinksToHomepage={logoLink}
            partner={partner}
            trackingContentEntry={trackingContentEntry}
          />
        </Wrapper>
      </Header>
    </>
  );
}

export { PartnerNavigation };
