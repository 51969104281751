import { ReactNode } from 'react';
import { ToastProvider as CUIToastProvider } from '@sumup/circuit-ui';

import { useScrolledPastThreshold } from '~/shared/hooks/use-scroll';
import { InfoBanner } from '~/shared/components/HeaderNavigation/interfaces';
import { SCROLL_THRESHOLD } from '~/shared/components/HeaderNavigation/constants';

type ToastProviderProps = {
  children: ReactNode;
  infoBanner?: InfoBanner;
  hideInfoBanner?: boolean;
};

function ToastProvider({
  children,
  infoBanner,
  hideInfoBanner,
}: ToastProviderProps) {
  const isSticky = useScrolledPastThreshold(SCROLL_THRESHOLD);
  const hasInfoBanner = !!infoBanner?.name && !isSticky && !hideInfoBanner;
  const customClassName = hasInfoBanner
    ? 'cui-website-toast-with-info-banner'
    : 'cui-website-toast';

  return (
    <CUIToastProvider position="top-right" className={customClassName}>
      {children}
    </CUIToastProvider>
  );
}

export default ToastProvider;
